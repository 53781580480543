/* eslint-disable max-len */
/* eslint-disable implicit-arrow-linebreak */
import { CheckTimeSlotDTO, TimeSpanDTO } from '../types/Calendar';
import { InternalEventDTO } from '../types/Event';
import { api, getPath } from './api';

const API_PATHS = Object.freeze({
  single: '/customer/{customerId}/internal-event/{itemId}',
  all: '/customer/{customerId}/internal-event',
  checkSlot: '/customer/{customerId}/internal-event/checkSlot',
});

class InternalEventServiceModel {
  getAllInternalEvents = (params: TimeSpanDTO) =>
    api.get(getPath(API_PATHS.all), { params });

  getInternalEvent = (id: string) => api.get(getPath(API_PATHS.single, id));

  updateInternalEvent = (id: string, data: InternalEventDTO) =>
    api.put(getPath(API_PATHS.single, id), data);

  createInternalEvent = (data: InternalEventDTO) =>
    api.post(getPath(API_PATHS.all), data);

  deleteInternalEvent = (id: string) =>
    api.delete(getPath(API_PATHS.single, id));

  checkCalendarSlotForInternalEvent = (data: CheckTimeSlotDTO) =>
    api.post(getPath(API_PATHS.checkSlot), data);
}

const InternalEventService = new InternalEventServiceModel();

export default InternalEventService;
