import { Box, Typography } from '@material-ui/core';
import { observer } from 'mobx-react-lite';
import { FC, useCallback, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { useHistory } from 'react-router-dom';
import { IconTextButton } from '../../components/IconTextButton/IconTextButton';
import { Layout } from '../../components/Layout';
import { CellRenderers } from '../../components/MaterialTable/CellRenderers';
import MaterialTable from '../../components/MaterialTable/MaterialTable';
import { SkyboxMap } from '../../components/SkyboxMap/SkyboxMap';
import { PATHS } from '../../constants/routes';
import { useStores } from '../../stores';
import { GetQueryParams, SortDirection } from '../../types/Common';
import { getPathWithId } from '../../utils/navigationUtils';
import { useTableNavigation } from '../../utils/useTableNavigation';

const PAGE_SIZE = 10;
const TABLE_NAVIGATION_DEFAULTS = {
  sortId: 'mapId',
};

export const SkyboxesScreen: FC = observer(() => {
  const {
    customerStore: { skyboxMap },
    authStore: { isAdmin, isOwner },
    skyboxStore: {
      skyboxListPage,
      getSkyboxList,
      getMyActiveSkyboxes,
      myActiveSkyboxes,
      skyboxTotal,
    },
  } = useStores();

  const { t } = useTranslation();
  const history = useHistory();

  const { query, onPageChange, onSortingChange } = useTableNavigation(
    TABLE_NAVIGATION_DEFAULTS,
  );

  const fetchSkyboxes = useCallback(async () => {
    const { page, sortId, sortDirection } = query;
    const skip = (page ?? 0) * PAGE_SIZE;
    const params: GetQueryParams = {
      take: PAGE_SIZE,
      skip,
      id: sortId ?? TABLE_NAVIGATION_DEFAULTS.sortId,
      desc: sortDirection !== SortDirection.ASC,
    };
    if (isAdmin) {
      return getSkyboxList(params);
    }
    if (isOwner) {
      return getMyActiveSkyboxes(params);
    }
  }, [query, getSkyboxList, getMyActiveSkyboxes, isAdmin, isOwner]);

  useEffect(() => {
    fetchSkyboxes();
  }, [fetchSkyboxes]);

  const onAddClick = () => {
    history.push(PATHS.skyboxes.add);
  };

  const COLUMNS = [
    {
      accessor: 'name',
      Header: t('common.name'),
      Cell: CellRenderers.linkIfActive((row) =>
        getPathWithId(PATHS.skyboxes.single, row.id),
      ),
    },
    {
      accessor: 'mapId',
      Header: t('common.id'),
      Cell: CellRenderers.linkIfActive((row) =>
        getPathWithId(PATHS.skyboxes.single, row.id),
      ),
    },
    {
      accessor: 'seatCount',
      Header: t('common.capacity'),
      Cell: CellRenderers.linkIfActive((row) =>
        getPathWithId(PATHS.skyboxes.single, row.id),
      ),
    },
    {
      accessor: 'active',
      Header: t('common.state'),
      Cell: CellRenderers.boolean('', t('skybox.deactivated')),
    },
  ];

  if (isAdmin) {
    COLUMNS.unshift({
      accessor: 'company.name',
      Header: t('common.owner'),
      Cell: CellRenderers.linkIfActive((row) =>
        getPathWithId(PATHS.skyboxes.single, row.id),
      ),
    });
  }

  const skyboxItems = isOwner ? myActiveSkyboxes : skyboxListPage;

  return (
    <Layout headerText={t('sidemenu.skyboxes')}>
      <Box p={3}>
        <Box
          display="flex"
          alignItems="center"
          justifyContent="space-between"
          mb={3}
        >
          <Box alignItems="center" display="flex">
            <Typography variant="body2">
              {t('skybox.skyboxCount', { count: skyboxTotal })}
            </Typography>
          </Box>

          <Box display="flex" alignItems="center">
            {skyboxMap ? <SkyboxMap source={skyboxMap} /> : null}
            {isAdmin && (
              <IconTextButton
                onClick={onAddClick}
                label="skybox.addNewSkybox"
                type="add"
                id={`add-new-skybox`}
              />
            )}
          </Box>
        </Box>

        <MaterialTable
          columns={COLUMNS}
          data={skyboxItems || []}
          onPageChange={onPageChange}
          onSortingChange={onSortingChange}
          totalCount={skyboxTotal}
          paginationEnabled
          useControlledState
          controlledPageIndex={query.page}
        />
      </Box>
    </Layout>
  );
});
