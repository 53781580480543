import { Box, BoxProps, Button, InputLabel } from '@material-ui/core';
import { ContentState, convertToRaw, EditorState } from 'draft-js';
import draftToHtml from 'draftjs-to-html';
import htmlToDraft from 'html-to-draftjs';
import { toJS } from 'mobx';
import { FC, useEffect, useState } from 'react';
import { Editor } from 'react-draft-wysiwyg';
import 'react-draft-wysiwyg/dist/react-draft-wysiwyg.css';
import { useTranslation } from 'react-i18next';
import colors from '../../styles/colors';

interface WysiwygProps extends Omit<BoxProps, 'onChange'> {
  value?: any;
  onChange?: Function;
  label?: string;
  toolbar?: any;
  isAlwaysEditable?: boolean;
}

export const Wysiwyg: FC<WysiwygProps> = ({
  onChange,
  value,
  label,
  toolbar,
  isAlwaysEditable,
  ...rest
}) => {
  const [initialized, setInitialized] = useState(false);
  const [editorState, setEditorState] = useState(EditorState.createEmpty());
  const [editing, setEditing] = useState(isAlwaysEditable ? true : false);
  const { t } = useTranslation();

  const onEditorStateChange = (editorState: any) => {
    setEditorState(editorState);
    const content = editorState.getCurrentContent();
    return onChange?.(
      content.hasText() ? draftToHtml(convertToRaw(content)) : '',
    );
  };

  useEffect(() => {
    if (!initialized) {
      if (value && typeof value === 'string') {
        const contentBlock = htmlToDraft(toJS(value));

        if (contentBlock) {
          const contentState = ContentState.createFromBlockArray(
            contentBlock.contentBlocks,
          );
          setEditorState(EditorState.createWithContent(contentState));
        }
        setInitialized(true);
      }
    }
  }, [value, setInitialized, initialized]);

  useEffect(() => {
    return () => {
      setInitialized(false);
      setEditorState(EditorState.createEmpty());
    };
  }, []);

  if (!toolbar) {
    toolbar = {
      options: ['inline', 'blockType', 'list', 'link'],
      blockType: {
        inDropdown: true,
        options: ['Normal', 'H1', 'H2', 'H3', 'H4'],
      },
      inline: {
        options: ['bold', 'italic', 'underline'],
      },
    };
  }

  const onEditClick = () => {
    setEditing(true);
  };

  const onBlur = () => {
    if (isAlwaysEditable) {
      return;
    }
    setEditing(false);
  };

  return (
    <Box width="100%" {...rest}>
      {label && (
        <Box mb={1}>
          <InputLabel>{label}</InputLabel>
        </Box>
      )}
      {editing && (
        <Box bgcolor="#fff">
          <Editor
            editorState={editorState}
            onEditorStateChange={onEditorStateChange}
            toolbar={toolbar}
            stripPastedStyles
            onBlur={onBlur}
            wrapperStyle={{
              color: '#333',
            }}
            editorStyle={{
              minHeight: '400px',
              maxHeight: '500px',
              overflowY: 'auto',
              border: '1px solid #ddd',
              padding: '1rem',
              color: '#333',
            }}
          />
        </Box>
      )}

      {!editing && (
        <Box display="flex">
          <Box
            py={1}
            overflow="hidden"
            px={3}
            minHeight={100}
            maxHeight={200}
            flex={1}
            mr={3}
            color={colors.text.body1}
            bgcolor={colors.background.bg2}
          >
            <div dangerouslySetInnerHTML={{ __html: value }} />
          </Box>
          <Box>
            <Button variant="outlined" onClick={onEditClick}>
              {t('common.edit')}
            </Button>
          </Box>
        </Box>
      )}
    </Box>
  );
};
