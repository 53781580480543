export const getUserDisplayName = (
  userInfo:
    | {
        firstName?: string;
        lastName?: string;
      }
    | undefined,
) => {
  if (!userInfo) {
    return 'MISSING_NAME';
  }

  const { firstName, lastName } = userInfo;
  return firstName && lastName
    ? `${firstName} ${lastName}`
    : firstName || lastName;
};

export const getUserDisplayNameOrEmail = (
  userInfo:
    | {
        email: string;
        firstName?: string;
        lastName?: string;
      }
    | undefined,
) => {
  return getUserDisplayName(userInfo) || userInfo!.email;
};
