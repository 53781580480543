import { Box, Button, Typography } from '@material-ui/core';
import { observer } from 'mobx-react-lite';
import React, { useCallback, useEffect, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { useHistory } from 'react-router';
import { Layout } from '../../components/Layout';
import MaterialTable from '../../components/MaterialTable/MaterialTable';
import { SearchInput } from '../../components/SearchInput';
import { PATHS } from '../../constants/routes';
import { useStore } from '../../stores';
import { IEventSeries } from '../../types/Event';
import { getPrettyDatePeriod } from '../../utils/i18nUtils';
import { useTableNavigation } from '../../utils/useTableNavigation';

const PAGE_SIZE = 10;

export const EventSerieListScreen: React.FC = observer(() => {
  const { getEventSeries, eventSeries, eventSeriesCount } =
    useStore('eventStore');

  const history = useHistory();
  const { t } = useTranslation();

  const { query, onPageChange, onSearchChange } = useTableNavigation();

  useEffect(() => {
    const { page, search } = query;
    const skip = (page ?? 0) * PAGE_SIZE;
    getEventSeries({ take: PAGE_SIZE, skip, search });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [query]);

  const onOpenEventSeriesClick = useCallback(
    (eventSeries: IEventSeries) => () => {
      const path = PATHS.skyboxEvents.eventList.replace(
        ':id',
        String(eventSeries.id),
      );

      history.push(path);
    },
    [history],
  );

  const columns = useMemo(() => {
    return [
      {
        Header: t('events.eventSeriesName'),
        accessor: 'title',
        width: '50%',
      },
      {
        Header: t('common.date'),
        accessor: 'startDate',
        Cell: ({ row: { original } }: any) => {
          return getPrettyDatePeriod(original.startDate, original.endDate);
        },
        width: '25%',
      },
      {
        Header: t('common.events'),
        accessor: 'eventCount',
        width: '10%',
      },
      {
        Header: t('common.action'),
        accessor: 'action',
        width: 275,
        Cell: ({ row: { original } }: any) => {
          return (
            <Button
              variant="outlined"
              onClick={onOpenEventSeriesClick(original)}
            >
              {t('events.openEvent')}
            </Button>
          );
        },
      },
    ];
  }, [onOpenEventSeriesClick, t]);

  return (
    <Layout headerText={t('events.eventSerie')}>
      <Box p={3}>
        <Box
          my={3}
          display="flex"
          justifyContent="space-between"
          alignItems="center"
        >
          <Typography color="textSecondary">
            {t('events.eventSeriesCount', { count: eventSeriesCount })}
          </Typography>
          <SearchInput onChange={onSearchChange} useDebounce />
        </Box>
        <MaterialTable
          columns={columns}
          data={eventSeries}
          onPageChange={onPageChange}
          totalCount={eventSeriesCount}
          defaultPageSize={PAGE_SIZE}
          paginationEnabled
          useControlledState
          controlledPageIndex={query.page}
        />
      </Box>
    </Layout>
  );
});
