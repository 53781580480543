import { observer } from 'mobx-react-lite';
import { FC } from 'react';
import { useTranslation } from 'react-i18next';
import { OwnerOrderListLayout } from '../../components/Layout';
import { IPurchase } from '../../types/Order';
import { getUserDisplayNameOrEmail } from '../../utils/getUserDisplayName';
import { getPrettyDate } from '../../utils/i18nUtils';
import { ListTableClickableCell } from './components/ListTableClickableCell';

export const OwnerUpcomingOrderListScreen: FC = observer(() => {
  const { t } = useTranslation();

  const getDate = (date: string) =>
    getPrettyDate(date, true, t('common.clockAbbr'));

  const COLUMNS = [
    {
      Header: t('common.eventSeries'),
      accessor: 'eventSkybox.event.title',
      width: 250,
      Cell: ({ row: { original } }: any) => (
        <ListTableClickableCell item={original}>
          {original?.eventSkybox?.event?.title}
        </ListTableClickableCell>
      ),
    },
    {
      Header: t('orders.date'),
      accessor: 'date',
      Cell: ({ row: { original } }: any) => (
        <ListTableClickableCell item={original}>
          {getDate(original.eventSkybox?.event?.date)}
        </ListTableClickableCell>
      ),
      width: 250,
    },
    {
      Header: t('orders.orderNumber'),
      accessor: 'livexOrderNumber',
      width: 100,
      Cell: ({ row: { original } }: any) => (
        <ListTableClickableCell item={original}>
          {original.purchase?.livexOrderNumber}
        </ListTableClickableCell>
      ),
    },
    {
      Header: t('orders.orderDate'),
      accessor: 'paidOn',
      width: 250,
      Cell: ({ row: { original } }: any) => (
        <ListTableClickableCell item={original}>
          {getDate(original.purchase?.paidOn)}
        </ListTableClickableCell>
      ),
    },
    {
      Header: t('common.skybox'),
      accessor: 'eventSkybox.skybox.mapId',
      Cell: ({ row: { original } }: any) => (
        <ListTableClickableCell item={original}>
          {original?.eventSkybox?.skybox?.mapId}
        </ListTableClickableCell>
      ),
    },
    {
      Header: t('orders.childOrders'),
      accessor: 'children',
      Cell: ({ row: { original } }: any) => {
        let children = original?.purchase?.children;
        if (!children) return 0;
        children = children.filter(
          (purchase: IPurchase) => purchase.paidOn || purchase.orderedOn,
        );
        return children.length;
      },
    },
    {
      Header: t('orders.renter'),
      accessor: 'renter',
      Cell: ({ row: { original } }: any) => {
        return (
          <ListTableClickableCell item={original}>
            {getUserDisplayNameOrEmail(original.purchase?.orderUserInfo)}
          </ListTableClickableCell>
        );
      },
    },
  ];

  return <OwnerOrderListLayout type="upcoming" columns={COLUMNS} />;
});
