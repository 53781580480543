import { Typography } from '@material-ui/core';
import { TFunction } from 'i18next';
import { camelCase } from 'lodash';
import {
  getUserDisplayName,
  getUserDisplayNameOrEmail,
} from '../../utils/getUserDisplayName';
import { Link } from '../Link';

export const CellRenderers = {
  link:
    (linkFn: (row: any) => string) =>
    ({ cell, row: { original } }: any) => (
      <Typography variant="body1">
        <Link to={linkFn(original)}>{cell.value || ''}</Link>
      </Typography>
    ),

  linkIfActive:
    (linkFn: (row: any) => string) =>
    (data: { cell: any; row: { original: any } }) => {
      return data.row.original.active
        ? CellRenderers.link(linkFn)(data)
        : data.cell.value;
    },

  text: ({ cell }: any) => (
    <Typography variant="body1">{cell.value}</Typography>
  ),

  name: ({ row: { original } }: any) => (
    <Typography variant="body1">{getUserDisplayName(original)}</Typography>
  ),

  nameOrEmail: ({ row: { original } }: any) => (
    <Typography variant="body1">
      {getUserDisplayNameOrEmail(original)}
    </Typography>
  ),

  roles: ({ cell }: any, t: TFunction) =>
    !Array.isArray(cell.value) ? (
      ''
    ) : (
      <Typography variant="body1">
        {cell.value
          .map((role: string) => t(`common.${camelCase(role)}`))
          .join(', ')}
      </Typography>
    ),

  textFn:
    (fn: (cell: any, row: any) => string) =>
    ({ cell, row: { original } }: any) => (
      <Typography variant="body1">{fn(cell, original)}</Typography>
    ),

  boolean:
    (trueString: string, falseString: string) =>
    ({ cell }: any) => (
      <Typography variant="body1">
        {cell.value === true ? trueString : falseString}
      </Typography>
    ),
};
