import { PaginationDTO } from './Common';
import { IEvent } from './Event';
import { IOwner } from './Owner';
import { ISkybox } from './Skybox';
import { ITicketPurchase } from './Ticket';
import { IEndUser } from './User';

export interface CreateCartDTO {
  eventId: number;
  parentId?: string;
}

export interface ReserveTicketObject {
  priceId: number;
  numberOfTickets: number;
}
export interface ReserveTicketsForSkyboxDTO {
  eventId: number;
  cartId: string;
  eventSeriesId: number;
  skyboxId: string;
  tickets: ReserveTicketObject[];
}

export interface AddSkyboxToCartDTO {
  eventId: number;
  skyboxId: string;
  cartId: string;
}

export interface ReserveMySkyboxDTO {
  eventSeriesId: number;
  eventId: number;
  skyboxId: string;
  contactName?: string;
  contactEmail?: string;
  contactPhone?: string;
}

export interface IVatObject {
  vatPercentage: number;
  vatValueSum: number;
  valueSum: number;
}

export interface ISkyboxInfoWithCompany extends ISkybox {
  company: IOwner;
}

export interface IPurchaseSkybox {
  contactEmail: string;
  contactName: string;
  contactPhone: string;
  eventSkyboxId: string;
  id: number;
  identifierText: string;
  price: number;
  purchaseId: string;
  skyboxName: string;
  vatAmount: number;
  skyboxInfo: ISkyboxInfoWithCompany;
}
export interface ICart {
  id: string;
  partySize?: {};
  servingInvoicingInfo?: string;
  expiresAt: string;
  tickets: ITicketPurchase[];
  vat: IVatObject[];
  eventSeriesId: number;
  eventId: number;
  purchaseSkyboxes: IPurchaseSkybox[];
  parent?: IPurchase;
  totalSum: number;
  orderFee: number;
  userId?: string;
  adminUserId?: string;
}

export interface ClaimShoppingCartForUserDTO {
  userId: string;
  cartId: string;
}

export interface CreateSkyboxInviteDTO {
  email: string;
  ticketId: string;
  purchaseId: string;
  ticketNumber: number;
  message?: string;
}

export interface IPaymentProvider {
  group: string;
  icon: string;
  id: string;
  name: string;
  parameters: { name: string; value: string }[];
  svg: string;
  url: string;
}
export interface GetOrdersDTO extends PaginationDTO {
  search?: string;
  adminOnly?: boolean;
  servingPurchasesOnly?: boolean;
  userOnly?: boolean;
  startDate?: string;
  endDate?: string;
  eventId?: number;
  eventSeriesId?: number;
  eventStartDate?: string;
  eventEndDate?: string;
  skipSave?: boolean;
  paymentMethod?: 'invoice' | 'all' | 'online_paymnet';
}

export interface GetOwnerOrdersDTO extends GetOrdersDTO {
  past?: boolean;
}

export interface IPaymentGroup {
  icon: string;
  id: string;
  name: string;
  svg: string;
}

export interface ICheckoutResponse {
  groups: IPaymentGroup[];
  href: string;
  providers: IPaymentProvider[];
  reference: string;
  terms: string;
  transactionId: string;
}

export enum PurchaseState {
  RESERVED = 'RESERVED',
  PROCESSING = 'PROCESSING',
  FAILED = 'FAILED',
  COMPLETE = 'COMPLETE',
  PAYMENT_INITIATED = 'PAYMENT_INITIATED',
  PARTIALLY_PAID = 'PARTIALLY_PAID',
}

export interface IPurchase {
  adminUserId: string;
  children: IPurchase[];
  expiresAt: string;
  orderFee: number;
  paidOn: string;
  servingInvoicingInfo?: string;
  invoicingInfo?: string;
  event: IEvent;
  orderedOn: string;
  parent?: IPurchase;
  orderUserInfo: IEndUser;
  totalSum: number;
  title: string;
  invoicingFee?: number;
  id: string;
  paymentMethod: string;
  tickets: ITicketPurchase[];
  eventDate: string;
  livexOrderNumber: string;
  purchaseSkyboxes: IPurchaseSkybox[];
  eventId: number;
  extraInfo?: string;
  extendedExtraInfo?: any;
  parentId: string;
  ticketFilename: string;
  state: PurchaseState;
}
