import { History, Location } from 'history';
import { pickBy } from 'lodash';
import { useMemo } from 'react';
import { useHistory, useLocation } from 'react-router-dom';
import { SortBy, SortDirection } from '../types/Common';

enum QueryParamKey {
  PAGE = 'page',
  SEARCH = 'search',
  SORT_ID = 'sortId',
  SORT_DIRECTION = 'sortDirection',
}

export interface QueryParams {
  [QueryParamKey.PAGE]?: number;
  [QueryParamKey.SEARCH]?: string;
  [QueryParamKey.SORT_ID]?: string;
  [QueryParamKey.SORT_DIRECTION]?: SortDirection;
}

const parsePageQuery = (query: string | null) => {
  const page = Number(query);
  return isNaN(page) ? 0 : page;
};

const navigateWithParams = (
  params: QueryParams,
  history: History,
  location: Location,
) => {
  history.push({
    pathname: location.pathname,
    search: new URLSearchParams(
      pickBy(params, Boolean) as Record<string, string>,
    ).toString(),
  });
};

export const useTableNavigation = (defaults?: { sortId?: string }) => {
  const location = useLocation();
  const history = useHistory();

  const query = useMemo(() => {
    const params = new URLSearchParams(location.search);
    return {
      page: parsePageQuery(params.get(QueryParamKey.PAGE)),
      search: params.get(QueryParamKey.SEARCH) || undefined,
      sortId: params.get(QueryParamKey.SORT_ID) || defaults?.sortId,
      sortDirection:
        params.get(QueryParamKey.SORT_DIRECTION) === SortDirection.DESC
          ? SortDirection.DESC
          : SortDirection.ASC,
    } as QueryParams;
  }, [location, defaults]);

  const onPageChange = async (page: number) => {
    if (page === query.page) {
      return;
    }
    navigateWithParams(
      {
        ...query,
        page,
      },
      history,
      location,
    );
  };

  const onSearchChange = async (value: string) => {
    navigateWithParams(
      {
        ...query,
        search: value,
        page: 0,
      },
      history,
      location,
    );
  };

  const onSortingChange = ({ sortBy }: { sortBy: SortBy[] }) => {
    if (!sortBy[0]) {
      return;
    }
    const { id, desc } = sortBy[0];
    if (
      !!desc === (query.sortDirection !== SortDirection.ASC) &&
      id === query.sortId
    ) {
      return;
    }
    navigateWithParams(
      {
        ...query,
        sortId: id,
        sortDirection: !!desc ? SortDirection.DESC : SortDirection.ASC,
      },
      history,
      location,
    );
  };

  return {
    query,
    onPageChange,
    onSearchChange,
    onSortingChange,
  };
};
