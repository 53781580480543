import { groupBy } from 'lodash';
import { ICart } from '../types/Order';
import { ITicketPurchase } from '../types/Ticket';

export const getCartQuantity = (cart: ICart) => {
  if (!cart) return 0;
  const { tickets, purchaseSkyboxes } = cart;
  let ticketsQuantity = (tickets && tickets.length) || 0;

  // If tickets are bundles
  if (
    tickets?.some(
      (ticket: ITicketPurchase) => ticket.eventimTicketData.SubscriptionID,
    )
  ) {
    const subsTickets = groupBy(tickets, 'eventimTicketData.SubscriptionID');
    ticketsQuantity = Object.keys(subsTickets).length;
  }
  const skyboxPurchasesQuantity = purchaseSkyboxes
    ? purchaseSkyboxes.length
    : 0;

  return ticketsQuantity + skyboxPurchasesQuantity;
};

/**
 * Returns items grouped by a property
 */
export const getGroupedCartItems = (
  items: any[] | ITicketPurchase[],
  property: string,
) => {
  const groupObj = groupBy(items, (item: any) => {
    return property.split('.').reduce((a, b) => a[b], item);
  });

  return Object.keys(groupObj).map((name: string) => ({
    id: name,
    title: name,
    price: getSum(groupObj[name] ?? []),
    quantity: groupObj[name]?.length ?? 0,
    children: groupObj[name],
  }));
};

/**
 * Returns a sum of given product
 */
export const getSum = (items: any[], accessor?: string) => {
  if (!items) return 0;
  const sum = items.reduce((reducedSum, item) => {
    let price = accessor
      ? accessor.split('.').reduce((a, b) => a[b], item)
      : item.price;
    if (!price) price = 0;
    return reducedSum + price * (item.quantity || 1);
  }, 0);
  return sum;
};

/**
 * Returns a total cart sum. Prices are derived from current saved cart state
 */
export const getCartSum = (cart?: ICart) => {
  if (!cart) return 0;
  const { tickets, purchaseSkyboxes, orderFee } = cart;
  const ticketsSum = getSum(tickets);
  const skyboxPurchasesSum = getSum(purchaseSkyboxes);

  return ticketsSum + skyboxPurchasesSum + orderFee;
};
