import { observer } from 'mobx-react-lite';
import { FC, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { useParams } from 'react-router-dom';
import { SkyboxEventLayout } from '../../components/Layout/hocs/SkyboxEventLayout';
import { SkyboxReservationSettings } from '../../components/SkyboxReservationSettings';
import { useStores } from '../../stores/index';

export const EventSettingsScreen: FC = observer(() => {
  const {
    skyboxStore: {
      eventSkyboxes,
      getEventSkyboxes,
      updateEventSkyboxSettings,
      getEventSkyboxSettings,
      eventSkyboxSettings,
    },
    eventStore: { getEvent, event, updateEventInformation },
  } = useStores();
  const { t } = useTranslation();
  const { eventId, eventSeriesId }: { eventId: string; eventSeriesId: string } =
    useParams();

  useEffect(() => {
    if (!event || String(event.id) !== String(eventId)) {
      getEvent(eventSeriesId, eventId);
    }
  }, [
    getEvent,
    event,
    eventId,
    eventSeriesId,
    getEventSkyboxes,
    eventSkyboxes,
  ]);

  useEffect(() => {
    getEventSkyboxes(eventId);
    getEventSkyboxSettings(eventId);
  }, [getEventSkyboxes, eventId, getEventSkyboxSettings]);

  const onSave = async (values: any) => {
    const eventInformation = { ...event?.customerEventInformation };
    eventInformation.skyboxListPrice = values.skyboxListPrice;
    await updateEventSkyboxSettings(eventId, values);
    await updateEventInformation(
      { eventIds: [parseInt(eventId)], ...eventInformation },
      true,
    );
    await getEvent(eventSeriesId, eventId);
  };

  return (
    <SkyboxEventLayout>
      <SkyboxReservationSettings
        data={eventSkyboxes || []}
        settings={eventSkyboxSettings}
        onSave={onSave}
        formTitle={t('events.eventSettings')}
        type="event"
      />
    </SkyboxEventLayout>
  );
});
