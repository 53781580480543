import axios from 'axios';
import { makeAutoObservable, runInAction } from 'mobx';
import AxessService from '../services/AxessService';
import CalendarReservationService from '../services/CalendarReservationService';
import EventService from '../services/EventService';
import InternalEventService from '../services/InternalEventService';
import SkyboxService, {
  GetEventSkyboxesByTimePeriodDTO,
} from '../services/SkyboxService';
import {
  CalendarReservationDTO,
  CheckTimeSlotDTO,
  GetAllCalendarReservationsDTO,
  IBigCalendarEvent,
  IBigCalendarExternalEvent,
  IBigCalendarInternalEvent,
  IBigCalendarReservation,
  ICalendarReservation,
  ICalendarSkyboxUsage,
  InvalidCalendarSlotWarning,
  TimeSpanDTO,
} from '../types/Calendar';
import { StoreState } from '../types/Common';
import { InternalEventDTO } from '../types/Event';
import { ISkybox } from '../types/Skybox';
import {
  calendarResponseToCalendarEvents,
  transformToCalendarEvent,
} from '../utils';
import AuthStore from './AuthStore';
import ToastStore from './ToastStore';

class CalendarModel {
  state: StoreState = 'Idle';

  calendarEvents?: IBigCalendarExternalEvent[] = undefined;

  calendarSkyboxUsage?: ICalendarSkyboxUsage[] = undefined;

  calendarReservation?: ICalendarReservation = undefined;

  calendarReservations?: IBigCalendarReservation[] = undefined;

  calendarSlotValid?: { type: InvalidCalendarSlotWarning } | boolean =
    undefined;

  internalEvent?: IBigCalendarEvent = undefined;

  internalEvents?: IBigCalendarInternalEvent[] = undefined;

  blockedSkyboxes?: ISkybox[] = undefined;

  allCalendarReservations?: ICalendarReservation[] = undefined;

  constructor() {
    makeAutoObservable(this);
  }

  get isLoading() {
    return this.state === 'Loading';
  }

  /**
   * CALENDAR BOOKINGS
   */
  createCalendarReservation = async (data: CalendarReservationDTO) => {
    this.state = 'Loading';
    try {
      await CalendarReservationService.createCalendarReservation(data);
      runInAction(() => {
        this.state = 'Success';
        ToastStore.showSuccess('successes.calendar.createCalendarReservation');
      });
    } catch (error) {
      runInAction(() => {
        ToastStore.showError('errors.calendar.createCalendarReservation');
        this.state = 'Error';
      });
      throw error;
    }
  };

  checkCalendarSlotForReservation = async (data: CheckTimeSlotDTO) => {
    this.state = 'Loading';
    try {
      const res =
        await CalendarReservationService.checkCalendarSlotForReservation(data);
      runInAction(() => {
        this.state = 'Success';
        this.calendarSlotValid = res.data;
      });
    } catch (error) {
      runInAction(() => {
        this.state = 'Error';
        if (axios.isAxiosError(error)) {
          this.calendarSlotValid = error?.response?.data;
        }
      });
      throw error;
    }
  };

  updateCalendarReservation = async (
    id: string,
    data: CalendarReservationDTO,
  ) => {
    this.state = 'Loading';
    try {
      await CalendarReservationService.updateCalendarReservation(id, data);
      runInAction(() => {
        this.state = 'Success';
        ToastStore.showSuccess('successes.calendar.updateCalendarReservation');
      });
    } catch (error) {
      runInAction(() => {
        ToastStore.showError('errors.calendar.updateCalendarReservation');
        this.state = 'Error';
      });
      throw error;
    }
  };

  deleteCalendarReservation = async (id: string) => {
    this.state = 'Loading';
    try {
      await CalendarReservationService.deleteCalendarReservation(id);
      runInAction(() => {
        this.state = 'Success';
        ToastStore.showSuccess('successes.calendar.deleteCalendarReservation');
      });
    } catch (error) {
      runInAction(() => {
        ToastStore.showError('errors.calendar.deleteCalendarReservation');
        this.state = 'Error';
      });
      throw error;
    }
  };

  getCalendarReservations = async (params: GetAllCalendarReservationsDTO) => {
    this.state = 'Loading';
    try {
      const res =
        await CalendarReservationService.getAllCalendarReservations(params);
      runInAction(() => {
        this.state = 'Success';
        if (AuthStore.isOwner) {
          this.calendarReservations = transformToCalendarEvent(
            res.data,
            'order',
          );
          this.allCalendarReservations = undefined;
        } else {
          this.allCalendarReservations = res.data;
          this.calendarReservations = undefined;
        }
      });
    } catch (error) {
      runInAction(() => {
        ToastStore.showError('errors.calendar.getCalendarReservations');
        this.state = 'Error';
      });
      throw error;
    }
  };

  /**
   * INTERNAL EVENTS
   */
  createInternalEvent = async (data: InternalEventDTO) => {
    this.state = 'Loading';
    try {
      await InternalEventService.createInternalEvent(data);
      runInAction(() => {
        this.state = 'Success';
        ToastStore.showSuccess('successes.calendar.createInternalEvent');
      });
    } catch (error) {
      runInAction(() => {
        ToastStore.showError('errors.calendar.createInternalEvent');
        this.state = 'Error';
      });
      throw error;
    }
  };

  updateInternalEvent = async (id: string, data: InternalEventDTO) => {
    this.state = 'Loading';
    try {
      await InternalEventService.updateInternalEvent(id, data);
      runInAction(() => {
        this.state = 'Success';
        ToastStore.showSuccess('successes.calendar.updateInternalEvent');
      });
    } catch (error) {
      runInAction(() => {
        ToastStore.showError('errors.calendar.updateInternalEvent');
        this.state = 'Error';
      });
      throw error;
    }
  };

  deleteInternalEvent = async (id: string) => {
    this.state = 'Loading';
    try {
      await InternalEventService.deleteInternalEvent(id);
      runInAction(() => {
        this.state = 'Success';
        ToastStore.showSuccess('successes.calendar.deleteInternalEvent');
      });
    } catch (error) {
      runInAction(() => {
        ToastStore.showError('errors.calendar.deleteInternalEvent');
        this.state = 'Error';
      });
      throw error;
    }
  };

  getInternalEvents = async (params: TimeSpanDTO) => {
    this.state = 'Loading';
    try {
      const res = await InternalEventService.getAllInternalEvents(params);
      runInAction(() => {
        this.state = 'Success';
        this.internalEvents = transformToCalendarEvent(
          res.data,
          'internal_event',
        );
      });
    } catch (error) {
      runInAction(() => {
        ToastStore.showError('errors.calendar.getInternalEvents');
        this.state = 'Error';
      });
      throw error;
    }
  };

  getCalendarEvents = async (params: TimeSpanDTO) => {
    this.state = 'Loading';
    try {
      this.state = 'Success';
      const response = await EventService.getSkyboxCalendarEvents(params);
      runInAction(() => {
        this.calendarEvents = calendarResponseToCalendarEvents(response.data);
      });
    } catch {
      ToastStore.showError('errors.calendar.getCalendarEvents');
      this.state = 'Error';
    }
  };

  checkCalendarSlotForInternalEvent = async (data: CheckTimeSlotDTO) => {
    this.state = 'Loading';
    try {
      const res =
        await InternalEventService.checkCalendarSlotForInternalEvent(data);
      runInAction(() => {
        this.state = 'Success';
        this.calendarSlotValid = res.data;
      });
    } catch (error) {
      runInAction(() => {
        this.state = 'Error';
        if (axios.isAxiosError(error)) {
          this.calendarSlotValid = error?.response?.data;
        }
      });
    }
  };

  resendAccessCodes = async (reservationId: string) => {
    this.state = 'Loading';
    try {
      await AxessService.resendCodes(reservationId);
      runInAction(() => {
        this.state = 'Success';
        ToastStore.showSuccess('successes.calendar.resendAccessCodes');
      });
    } catch (error) {
      runInAction(() => {
        this.state = 'Error';
        ToastStore.showError('errors.calendar.resendAccessCodes');
      });
      throw error;
    }
  };

  getBlockedSkyboxes = async (startDate: Date, endDate: Date) => {
    this.state = 'Loading';
    try {
      const res = await CalendarReservationService.getBlockedSkyboxes({
        startDate,
        endDate,
      });
      runInAction(() => {
        this.state = 'Success';
        this.blockedSkyboxes = res.data;
      });
      return res;
    } catch (error) {
      runInAction(() => {
        this.state = 'Error';
      });
      throw error;
    }
  };

  getSkyboxUsage = async (params: GetEventSkyboxesByTimePeriodDTO) => {
    this.state = 'Loading';
    try {
      const res = await SkyboxService.getEventSkyboxesByTimePeriod(params);

      runInAction(() => {
        this.calendarSkyboxUsage = res.data;
        this.state = 'Success';
      });
    } catch (error) {
      ToastStore.showError('errors.skybox.getSkyboxUsage');
      runInAction(() => {
        this.state = 'Error';
      });
      throw error;
    }
  };
}

const CalendarStore = new CalendarModel();

export default CalendarStore;
