import { faTags } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Box, makeStyles, Typography } from '@material-ui/core';
import moment from 'moment-timezone';
import { FC } from 'react';
import { ToolbarProps } from 'react-big-calendar';
import colors from '../../styles/colors';
import {
  IBigCalendarExternalEvent,
  IBigCalendarInternalEvent,
  IBigCalendarReservation,
} from '../../types/Calendar';
import { getUserDisplayNameOrEmail } from '../../utils/getUserDisplayName';

export interface ReservationCalendarEventProps extends ToolbarProps {
  title: string;
  event:
    | IBigCalendarInternalEvent
    | IBigCalendarReservation
    | IBigCalendarExternalEvent;
}

const useStyles = makeStyles({
  title: {
    fontWeight: 600,
    lineHeight: '16px',
    fontSize: '0.75rem',
    color: colors.text.inverted,
  },
  date: {
    fontSize: '0.6rem',
    marginTop: '4px',
    color: colors.text.inverted,
  },
  box: {
    height: '100%',
    boxShadow: '-1px -1px 4px 4px rgba(0,0,0,0.25)',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'space-between',
  },
  footer: {
    display: 'flex',
    justifyContent: 'flex-end',
  },
});

export const ReservationCalendarEvent: FC<ReservationCalendarEventProps> = ({
  title,
  event,
}) => {
  const classes = useStyles();

  const getTime = (date: Date) => moment(date).format('HH:mm');

  const getColor = () => {
    switch (event.type) {
      case 'internal_event':
        return colors.blue3;
      case 'external_event':
        return colors.geekblue3;
      default:
        return colors.red3;
    }
  };
  return (
    <Box
      p={1}
      className={classes.box}
      bgcolor={getColor()}
      height="100%"
      borderRadius={5}
    >
      <Box>
        <Typography className={classes.title}>{title}</Typography>
        {event.type === 'order' && (
          <Box mt={1}>
            <Typography className={classes.title}>
              {getUserDisplayNameOrEmail(event.user)}
            </Typography>
          </Box>
        )}
        <Typography className={classes.date}>{`${getTime(
          event.startDate,
        )}–${getTime(event.endDate)}`}</Typography>
      </Box>
      {event.type === 'external_event' && (
        <Box className={classes.footer}>
          <FontAwesomeIcon icon={faTags} />
        </Box>
      )}
    </Box>
  );
};
