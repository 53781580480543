/* eslint-disable react-hooks/exhaustive-deps */
import { Box, Button, Grid, Typography } from '@material-ui/core';
import { observer } from 'mobx-react-lite';
import { makeRequired, makeValidate, Radios, Select, TextField } from 'mui-rff';
import { FC, useEffect, useState } from 'react';
import { Form, FormSpy } from 'react-final-form';
import { useTranslation } from 'react-i18next';
import { useStores } from '../../stores/index';
import {
  IBigCalendarInternalEvent,
  InvalidCalendarSlotWarning,
} from '../../types/Calendar';
import { InternalEventDTO, InternalEventType } from '../../types/Event';
import { ISkybox } from '../../types/Skybox';
import { prepareTextValues } from '../../utils/formUtils';
import { Validator } from '../../utils/validation';
import { DateTimePicker } from '../DateTimePicker/DateTimePicker';
import { CommonModal } from '../Modals';
import { CommonModalProps } from '../Modals/CommonModal';
import { IReservationSlot } from './ReservationCalendar';
import { ReservationCalendarWarning } from './ReservationCalendarWarning';

export interface ReservationCalendarInternalEventModalProps
  extends Omit<CommonModalProps, 'children' | 'type' | 'onClose' | 'open'> {
  handleClose: (refreshData: boolean) => void;
  selectedSlot?: IReservationSlot;
  selectedEvent?: IBigCalendarInternalEvent;
}

export const ReservationCalendarInternalEventModal: FC<ReservationCalendarInternalEventModalProps> =
  observer(({ handleClose, selectedSlot, selectedEvent }) => {
    const [defaultValues, setDefaultValues] = useState<InternalEventDTO>();
    const [initialized, setInitialized] = useState(false);
    const [prevFromValues, setPrevFormValues] = useState<InternalEventDTO>();
    const [skyboxSelectData, setSkyboxSelectData] = useState<
      { value: string; label: string }[]
    >([]);
    const { t } = useTranslation();
    const [warning, setWarning] = useState<InvalidCalendarSlotWarning>();

    const {
      authStore: { isOwner },
      skyboxStore: { getActiveSkyboxes, activeSkyboxes },
      calendarStore: {
        createInternalEvent,
        deleteInternalEvent,
        updateInternalEvent,
        checkCalendarSlotForInternalEvent,
        calendarSlotValid,
      },
    } = useStores();

    useEffect(() => {
      if (selectedSlot) {
        setDefaultValues({
          name: '',
          extraInfo: '',
          startDate: selectedSlot.start,
          endDate: selectedSlot.end,
          type: 'disallow_skybox_sales',
        });
      } else if (selectedEvent) {
        let type: InternalEventType;
        type = selectedEvent.allowSkyboxSales
          ? 'allow_skybox_sales'
          : 'disallow_skybox_sales';
        if (selectedEvent.disabledSkyboxes.length) {
          type = 'disallow_some_skybox_sales';
        }
        setDefaultValues({
          ...selectedEvent,
          startDate: selectedEvent.startDate,
          endDate: selectedEvent.endDate,
          type,
        });
      }
    }, [setDefaultValues, selectedSlot, selectedEvent]);

    // Checks for timeslot validity
    useEffect(() => {
      if (typeof calendarSlotValid !== 'object') {
        setWarning(undefined);
      } else {
        setWarning(calendarSlotValid.type);
      }
    }, [calendarSlotValid]);

    const schema = Validator.object().shape({
      startDate: Validator.date().required(),
      endDate: Validator.date()
        .required()
        .min(Validator.ref('startDate'), () =>
          t('validation.startDateBeforeEndDate'),
        ),
      name: Validator.string().required(),
    });

    /**
     * Checks if selected calendar slot is free
     */
    const checkSlot = async (values: InternalEventDTO) => {
      const { startDate, endDate } = values;
      await checkCalendarSlotForInternalEvent({
        startDate,
        endDate,
        ignoreId: selectedEvent?.id,
      });
    };

    useEffect(() => {
      if (!initialized) {
        if (!isOwner) {
          getActiveSkyboxes();
        }
        setInitialized(true);
      }
    }, [getActiveSkyboxes, initialized, setInitialized, isOwner, checkSlot]);

    useEffect(() => {
      const selectValues = (activeSkyboxes ?? []).map((skybox: ISkybox) => ({
        value: skybox.id,
        label: skybox.name,
      }));
      selectValues?.unshift({ value: '', label: t('common.select') });
      setSkyboxSelectData(selectValues || []);
    }, [activeSkyboxes, setSkyboxSelectData, t]);

    const validate = makeValidate(schema);
    const required = makeRequired(schema);

    const onSubmit = async (values: InternalEventDTO) => {
      prepareTextValues(['extraInfo'], values);
      if (!selectedEvent) {
        await createInternalEvent(values);
      } else {
        await updateInternalEvent(selectedEvent.id, values);
      }
      handleClose(true);
    };

    const onDeleteClick = async () => {
      if (!selectedEvent) {
        return;
      }
      await deleteInternalEvent(selectedEvent.id);
      handleClose(true);
    };

    const RADIOS = [
      'disallow_skybox_sales',
      'allow_skybox_sales',
      'disallow_some_skybox_sales',
    ].map((type) => ({
      value: type,
      label: t(`calendar.internalEventModal.effectChoice.${type}`),
    }));

    const onFormChange = async ({ values }: { values: InternalEventDTO }) => {
      if (
        values.startDate !== prevFromValues?.startDate ||
        values.endDate !== prevFromValues?.endDate
      ) {
        await checkSlot(values);
      }
      setTimeout(() => {
        setPrevFormValues(values);
      });
    };

    return (
      <CommonModal open type="normal">
        <>
          {warning && <ReservationCalendarWarning type={warning} />}
          <Form
            initialValues={defaultValues}
            validate={validate as any}
            onSubmit={onSubmit}
            render={({ handleSubmit, values, hasValidationErrors }) => {
              let label = 'updateEvent';
              if (!selectedEvent) {
                label = 'addNewEvent';
              }
              if (isOwner) {
                label = 'event';
              }

              return (
                <form onSubmit={handleSubmit} noValidate>
                  <Box
                    display="flex"
                    justifyContent="space-between"
                    alignItems="center"
                  >
                    <Typography variant="h2">
                      {t(`calendar.internalEventModal.${label}`)}
                    </Typography>
                    {selectedEvent && !isOwner && (
                      <Button variant="outlined" onClick={onDeleteClick}>
                        {t('calendar.internalEventModal.deleteEvent')}
                      </Button>
                    )}
                  </Box>

                  <Box my={3}>
                    <Typography variant="h3">
                      {t('calendar.internalEventModal.eventDetails')}
                    </Typography>
                  </Box>

                  <Grid container spacing={3}>
                    <Grid xs={7} item>
                      <Box mb={2}>
                        <TextField
                          disabled={isOwner}
                          label={t('common.name')}
                          name="name"
                          fullWidth
                          required={required.name}
                        />
                      </Box>
                      <Grid container spacing={3}>
                        <Grid item xs={6}>
                          <DateTimePicker
                            disabled={isOwner}
                            name="startDate"
                            label={t('calendar.internalEventModal.startDate')}
                            required={required.startDate}
                          />
                        </Grid>
                        <Grid item xs={6}>
                          <DateTimePicker
                            disabled={isOwner}
                            name="endDate"
                            label={t('calendar.internalEventModal.endDate')}
                            required={required.endDate}
                          />
                        </Grid>
                      </Grid>
                    </Grid>

                    <Grid xs={5} item>
                      <TextField
                        fullWidth
                        multiline
                        rows={12}
                        label={t('calendar.internalEventModal.extraInfo')}
                        name="extraInfo"
                        disabled={isOwner}
                      />
                    </Grid>
                  </Grid>

                  {!isOwner && (
                    <Box>
                      <Box mt={3} mb={1}>
                        <Typography variant="h3">
                          {t('calendar.internalEventModal.effectOnSkyboxes')}
                        </Typography>
                      </Box>
                      <Radios name="type" data={RADIOS} disabled={isOwner} />
                      {!isOwner &&
                        values.type === 'disallow_some_skybox_sales' && (
                          <Select
                            name="disabledSkyboxes"
                            data={skyboxSelectData}
                            displayEmpty
                            multiple
                          />
                        )}
                    </Box>
                  )}

                  <Box mt={3} display="flex">
                    <Box mr={1}>
                      <Button
                        disabled={hasValidationErrors || isOwner}
                        type="submit"
                      >
                        {t('common.save')}
                      </Button>
                    </Box>
                    <Button
                      variant="outlined"
                      onClick={() => handleClose(false)}
                    >
                      {t('common.cancel')}
                    </Button>
                  </Box>
                  <FormSpy
                    subscription={{ values: true }}
                    onChange={onFormChange}
                  />
                </form>
              );
            }}
          />
        </>
      </CommonModal>
    );
  });
